.languageButton {
  background-color: transparent;
  border: 0;
  color: white;
}

.languageButtonFr {
  margin-left: 5px;

}

.languageButtonEn {
  border-left: 1px solid rgb(77, 83, 88);
  margin-right: 10px;
  margin-left: 0px;
}