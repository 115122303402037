@use '@carbon/react/scss/zone';

h3 {
    font-weight: 600;
}
p{
    text-align: justify
}
.cds--side-nav {
    position: sticky;
    z-index: 999;
    top: 6rem;
    left: 80%;

    border-left: 4px solid #E0E0E0;
}

a.cds--side-nav__link,
.cds--side-nav a.cds--header__menu-item,
.cds--side-nav .cds--header__menu-title[aria-expanded=true]+.cds--header__menu {
    text-decoration: none !important;
    width: fit-content;
}

.cds--side-nav__link-text {
    font-size: larger !important;
    font-weight: 400 !important;
    line-height: 40px !important;
    width: max-content;
}

a.cds--header__menu-item {
    color:white;
    font-weight: 600;

}

.cds--header__menu .cds--header__menu-item {
    height: 4rem;
    width: 25rem;
    transform: translateX(-50%);
}
