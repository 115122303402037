@use '@carbon/react/scss/spacing' as *;

.home {
  margin-top: 47px;
}

.title {
  height: 400px;
  background-image: url('../../../public/images/AdobeStock_612662955 1.png');
  background-size: cover;
  position: relative;
}

.titleText {
  position: absolute;
  bottom: 30px;
  left: 30px;
}

.topDescription {
  margin-top: 50px;

  span {
    color: #0f62fe;
  }
}

.legend {
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
}

.textP {
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.prezProg {
  position: relative;
}

.prezProgText {
  position: absolute;
  left: 30px;
  top: 100px;
}

.presentationParts {
  margin-top: 50px;
}

.reallyBigBlueNumber {
  color: rgba(15, 98, 254, 1);
  font-size: 8rem;
  font-weight: 200;
  line-height: 12.5rem;
  letter-spacing: 0em;
  text-align: left;
}

.reallyBigBlueNumberLegend {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
}

.flexBox {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

/* Acteurs d'AIDA */
.actorsParts {
  margin-top: 50px;
}

.actors {
  padding-top: 20px;
  padding-bottom: 20px;

  span {
    color: #0f62fe;
  }
}
.imageSelected{
  cursor: pointer;
  margin-right: 10px;
  border:2px solid #0f62fe;
}
.imageNotSelected{
  cursor: pointer;
  margin-right: 10px;
}
.actorDescription{
  height: 250px;
  overflow-y: scroll;
}

.grayText {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #6f6f6f;
}

/*Défis*/
.defis {
  padding-top: 50px;
  padding-bottom: 50px;

  span {
    color: #0f62fe;
  }

}

.cds--accordion__title {
  font-weight: 600 !important;
}

/* Challenge=Theme */

.challenges {
  //border-bottom: 1px solid rgba(77, 83, 88, 1);

  a {
    color: white;
    text-decoration: none;
  }
}

.challengeBox {
  border-bottom: 1px solid rgba(77, 83, 88, 1);
  border-left: 1px solid rgba(77, 83, 88, 1);
  padding: 30px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 30vh;
}


.challengeBoxTitle {
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  // margin-bottom: 30px;
}

.challengeBoxBottom {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.challengeBoxPlus1 {
  display: flex;
  align-items: center;
  float: right;
}

.challengeBoxPlus {
  display: flex;
  align-items: center;
}

.arrowIcon {
  height: 16px;
  margin-left: 10px;
}

.blueNumber {
  font-size: 60px;
  font-weight: 200;
  line-height: 78px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(15, 98, 254, 1);
}

.challengesTitle {
  margin-top: 30px;
}

.bigBlueNumber {
  font-size: 100px;
  font-weight: 200;
  line-height: 130px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(15, 98, 254, 1);
  margin-top: 60px;
}

.challengesSubTitle {
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
}


.disclaimer {
  text-align: center;
  color: white;
  background-color: rgb(15, 98, 254);
  font-size: larger;
  padding: 10px;
}

.homeContentBottomRight {
  font-size: 14px;
  line-height: 20px;
}