.pointIBM {
  background: #edf5ff;
  padding: 16px 54px 16px 16px;
  border-radius: 8px;
  border: 1px solid #0f62fe;
  width: 100%;

  span{color:#0F62FE !important;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    }

font-size: 12px;
font-weight: 400;
line-height: 16px;
letter-spacing: 0em;


}
