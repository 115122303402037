.bottomBox {
  padding: 40px;
}

.contactWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin-top: 20px;
}

.companyContainer {
  margin-bottom: 40px;
}

.contactItem {
  margin-bottom: 10px; /* Réduire l'espacement entre les contacts */
  text-align: center;
}

.companyName {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px; /* Réduire l'espacement entre le nom de la société et les contacts */
  text-align: center;
  margin: 10px;
}


.contactfooter {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  display: block;
  margin: 0 auto;
}

.contactName {
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
  color: white;
  text-decoration: none !important;
}
