.theme {
  margin-top: 47px;
}

.title {
  padding-top: 60px;
  padding-bottom: 60px;

  span {
    color: #78a9ff;
  }
}

.numberBox {
  display: flex;
}

.blueNumber {
  font-size: 100px;
  font-weight: 300;
  color: rgba(15, 98, 254, 1);
  align-self: flex-end;
}

.avanceBox {
  border-bottom: 1px solid #E0E0E0;
  padding: 30px 0px 30px 0px;

  span {
    color: #6f6f6f;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.3199999928474426px;
  }
}

.navigationBox {
  padding-top: 30px;
  padding-bottom: 60px;
}

.nextNav {
  display: flex;
  justify-content: flex-end;
}

.navButton {
  align-items: center;
}

.sectionNav {
  top: 30%;
  right: 30px;
  position: fixed;
  // border-left: 4px solid #E0E0E0;
  width: 15%;
  z-index: 1;
  background-color: white;

  a {
    color: black;
    text-decoration: none;
  }
}

.currentTitle {
  color: rgba(15, 98, 254, 1) !important;
  font-weight: bold;
}

.pointSofteam{
  background: #edf5ff;
  padding: 16px 54px 16px 16px;
  border-radius: 8px;
  border: 1px solid #0f62fe;
  width: 100%;

  span{color:#0F62FE !important;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    }

font-size: 12px;
font-weight: 400;
line-height: 16px;
letter-spacing: 0em;


}
